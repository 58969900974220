<template>
  <ykc-table
    ref="YkcTable"
    :data="tableData"
    :columns="tableColumns"
    :title="tableTitle"
    operateFixedType="right"
    :operateButtons="tableOperateButtons"
    :selectable="false"
    :selectOnIndeterminate="false"
    @row-click="rowClick">
    <ykc-pagination
      slot="pagination"
      ref="YkcPagination"
      :total="pageInfo.total"
      :current-page.sync="pageInfo.pageIndex"
      :page-size.sync="pageInfo.pageSize"
      @current-change="handlePaginationCurrentChange"
      @size-change="handlePaginationSizeChange"></ykc-pagination>
  </ykc-table>
</template>
<script>
  import YkcTable from '@/components/ykc-ui/table';
  import YkcPagination from '@/components/ykc-ui/pagination';
  import { sysNotice } from '@/service/apis';
  import { sessionGetItem } from '@/utils';

  export default {
    components: {
      YkcTable,
      YkcPagination,
    },
    props: {},
    data() {
      return {
        tableData: [],

        tableColumns: [
          {
            label: '公告类型',
            prop: 'noticeType',
            minWidth: '80px',
            scopedSlots: {
              default: ({ row }) => {
                const noticeTypeMap = {
                  0: '营销公告',
                  1: '系统公告',
                };
                return (
                  <span title={noticeTypeMap[row.noticeType]}>{noticeTypeMap[row.noticeType]}</span>
                );
              },
            },
          },
          { label: '公告标题', prop: 'noticeTitle', minWidth: '100px' },
          {
            label: '状态',
            prop: 'pushStatus',
            minWidth: '60px',
            scopedSlots: {
              default: ({ row }) => {
                let pushStatus = '未读';
                if (row.pushStatus === 2) {
                  pushStatus = '已读';
                }
                return <span title={pushStatus}>{pushStatus}</span>;
              },
            },
          },
          { label: '公告内容', prop: 'noticeContent', minWidth: '140px' },
          { label: '推送时间', prop: 'publishTime', minWidth: '120px' },
        ],
        tableTitle: '',

        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      // 全部设为已读
      setRead() {
        this.tableData = this.tableData.map(item => {
          item.pushStatus = 2;
          return item;
        });
      },
      // 单行点击
      rowClick(row) {
        if (row.pushStatus !== 2) {
          sysNotice.allRead([row.id]).then(res => {
            console.log(res);
          });
        }
        this.$emit('rowClick', row);
      },
      searchTableList() {
        const reqParams = {
          accountId: sessionGetItem('accountId'),
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        sysNotice.queryNoticeListByOpera(reqParams).then(res => {
          console.log(res);
          this.tableData = res?.records || [];
          this.pageInfo.total = res?.total;
        });
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    ::v-deep .el-table {
      border-top: none;
      padding-bottom: 30px;
    }
    ::v-deep .ykc-table-pagination-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #ffffff;
      width: 100%;
      padding-bottom: 20px;
      .ykc-pagination {
        justify-content: center !important;
      }
    }
    ::v-deep .el-table__row {
      cursor: pointer;
    }
  }
</style>
