function buildId(list, parentId = '0') {
  return list.map((o, i) => {
    o.id = parentId === '0' ? `${i + 1}` : `${parentId}-${i + 1}`;
    o.parentId = parentId;
    if (o.subNodes) {
      o.subNodes = buildId(o.subNodes, o.id);
    }
    return o;
  });
}

function getPermissionMenu() {
  const menuList = [
    {
      name: '电站资产',
      icon: 'organization',
      subNodes: [
        {
          name: '电站管理',
          subNodes: [
            {
              name: '直联电站',
              path: '/plantAssets/powerStationManagement/directConnectionStation/List',
            },
            {
              name: '互联电站',
              path: '/plantAssets/powerStationManagement/interconnectionStation/List',
            },
          ],
        },
        {
          name: '电桩管理',
          subNodes: [
            {
              name: '直联电桩',
              path: '/plantAssets/powerPileManagement/directConnectionPile/List',
            },
            {
              name: '互联电桩',
              path: '/plantAssets/powerPileManagement/interconnectionPile/List',
            },
          ],
        },
        {
          name: '有序管理',
          subNodes: [
            {
              name: '有序台区管理',
              path: '/plantAssets/orderlyManagement/List',
            },
          ],
        },
        {
          name: '道闸管理',
          subNodes: [
            {
              name: '道闸管理',
              path: '/plantAssets/gateManagement/List',
            },
          ],
        },
        {
          name: '品牌型号',
          subNodes: [
            {
              name: '品牌型号',
              path: '/plantAssets/brandAndModel/List',
            },
          ],
        },
      ],
    },
    {
      name: '订单管理',
      icon: 'organization',
      subNodes: [
        {
          name: '充电订单',
          subNodes: [
            {
              name: '实时订单',
              path: '/orderManagement/realTimeOrder/List',
            },
            {
              name: '历史订单',
              path: '/orderManagement/historicalOrder/List',
            },
            {
              name: '异常订单',
              path: '/orderManagement/abnormalOrder/List',
            },
          ],
        },
        {
          name: '占位订单',
          subNodes: [
            {
              name: '占位订单',
              path: '/orderManagement/placeholderOrder/List',
            },
          ],
        },
      ],
    },
    {
      name: '网点管理',
      icon: 'organization',
      subNodes: [
        {
          name: '代理人管理',
          subNodes: [
            {
              name: '代理人列表',
              path: '/stationManage/agentManage',
            },
            {
              name: '运营商线索',
              path: '/stationManage/operatorClue',
            },
            {
              name: '电站线索',
              path: '/stationManage/stationClue',
            },
          ],
        },
      ],
    },
    {
      name: '财务管理',
      icon: 'organization',
      subNodes: [
        {
          name: '账户管理',
          subNodes: [
            {
              name: 'C端司机账户',
              path: '/financialManagement/accountManagement/cSideDriverAccounts',
            },
            {
              name: '客户账户',
              subNodes: [
                {
                  name: '客户账户',
                  path: '/financialManagement/customerAccount/customerAccounts',
                },
                {
                  name: '司机账户',
                  path: '/financialManagement/customerAccount/customDriverAccounts',
                },
              ],
            },
            {
              name: '子公司账户',
              subNodes: [
                {
                  name: '内部客户账户',
                  path: '/financialManagement/subsidiaryAccount/internalCustomerAccounts',
                },
                {
                  name: '司机账户',
                  path: '/financialManagement/subsidiaryAccount/subDriverAccounts',
                },
              ],
            },
            {
              name: '互联互通账户',
              path: '/financialManagement/interconnectedAccount/interconnectedAccounts',
              // subNodes: [
              //   {
              //     name: ' 互联互通账户',
              //     path: '/financialManagement/interconnectedAccount/interconnectedAccounts',
              //   },
              // ],
            },
          ],
        },
        {
          name: '对账管理',
          subNodes: [
            {
              name: '平台收支账单',
              path: '/financialManagement/platformReceiptsPaymentsStatement/platformReceiptsPaymentsStatements',
            },
            {
              name: '互联互通账单',
              path: '/financialManagement/interconnectedBillingStatement/interconnectedBillingStatements',
            },
            {
              name: '商户账单',
              path: '/financialManagement/merchantStatement/merchantStatements',
            },
            {
              name: '平台资金流账单',
              path: '/financialManagement/platformFundFlowStatement/platformFundFlowStatements',
            },
            {
              name: '占位费收支账单',
              path: '/financialManagement/occupancyFeeReceiptsPayments/occupancyFeeReceiptsPayments',
            },
            {
              name: '客户账单',
              subNodes: [
                {
                  name: '客户账单',
                  path: '/financialManagement/customerStatement/customerStatements',
                },
                {
                  name: '子机构账单',
                  path: '/financialManagement/subAgencyBill/subAgencyBill',
                },
              ],
            },
          ],
        },
        {
          name: '发票管理',
          path: '/financialManagement/invoiceManagement/invoiceManagements',
        },
      ],
    },
    {
      name: '运营中心',
      icon: 'order',
      subNodes: [
        {
          name: '价格费用管理',
          subNodes: [
            {
              name: '充电价格策略',
              path: '/operationCenter/priceManage/chargingPriceStrategy',
            },
            {
              name: '占用价格策略',
              path: '/operationCenter/priceManage/occupyingPriceStrategy',
            },
            {
              name: '抽成费用策略',
              path: '/operationCenter/priceManage/royaltyStrategy',
            },
          ],
        },
        {
          name: '会员管理',
          subNodes: [
            {
              name: '会员管理',
              path: '/operationCenter/vipManage/vipManage',
            },
            {
              name: '会员等级配置',
              path: '/operationCenter/vipManage/vipLevel',
            },
          ],
        },
        {
          name: '运营配置',
          subNodes: [
            {
              name: '站点运营配置',
              path: '/operationCenter/operationConfig/siteOperationConfig',
            },
            {
              name: '用户黑名单',
              path: '/operationCenter/operationConfig/userBlackList',
            },
            {
              name: '可用站点配置',
              path: '/operationCenter/operationConfig/availableSitesConfig',
            },
          ],
        },
      ],
    },
    {
      name: '营销管理',
      icon: 'order',
      subNodes: [
        {
          name: '实体充电卡',
          subNodes: [
            {
              name: '充电卡管理',
              path: '/marketingCenter/chargingCard/chargingCardManage',
            },
            {
              name: '离线充电卡',
              path: '/marketingCenter/chargingCard/offlineCardManage',
            },
          ],
        },
        {
          name: '平台营销工具',
          subNodes: [
            {
              name: '营销活动',
              subNodes: [
                {
                  name: '平台价格活动',
                  path: '/marketingCenter/platformMarketingTools/marketingActivity/platformPriceActivity',
                },
                {
                  name: '平台卡券活动',
                  path: '/marketingCenter/platformMarketingTools/marketingActivity/platformCouponActivity',
                },
              ],
            },
            {
              name: '平台卡券管理',
              subNodes: [
                {
                  name: '优惠券管理',
                  path: '/marketingCenter/platformMarketingTools/platformCoupon/coupon',
                },
                {
                  name: '抵用卡管理',
                  path: '/marketingCenter/platformMarketingTools/platformCoupon/creditCard',
                },
              ],
            },
            {
              name: '积分商城',
              subNodes: [
                {
                  name: '商品管理',
                  path: '/marketingCenter/platformMarketingTools/pointsMall/commodity',
                },
                {
                  name: '兑换管理',
                  path: '/marketingCenter/platformMarketingTools/pointsMall/exchange',
                },
              ],
            },
          ],
        },
        {
          name: '商户营销工具',
          subNodes: [
            {
              name: '营销活动',
              subNodes: [
                {
                  name: '商户价格活动',
                  path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantPriceActivity',
                },
                {
                  name: '商户卡券活动',
                  path: '/marketingCenter/merchantMarketingTools/marketingActivity/merchantCouponActivity',
                },
              ],
            },
            {
              name: '商户卡券管理',
              subNodes: [
                {
                  name: '优惠券管理',
                  path: '/marketingCenter/merchantMarketingTools/merchantCoupon/coupon',
                },
                {
                  name: '抵用卡管理',
                  path: '/marketingCenter/merchantMarketingTools/merchantCoupon/creditCard',
                },
              ],
            },
          ],
        },
        {
          name: '广告管理',
          subNodes: [
            {
              name: '用户端广告',
              path: '',
            },
          ],
        },
        {
          name: '标签分组',
          subNodes: [
            {
              name: '标签分组',
              path: '',
            },
          ],
        },
      ],
    },
    {
      name: '价格管理',
      icon: 'finance',
      subNodes: [
        {
          name: '价格配置',
          subNodes: [
            {
              name: '网点标准采购价',
              path: '/priceConfig/purchasePrice',
            },
            {
              name: '网点特殊采购价',
              path: '/priceConfig/specialPurchasePrice',
            },
            {
              name: '客户标准销售价',
              path: '/priceConfig/sellingPrice',
            },
            {
              name: '客户特殊销售价',
              path: '/priceConfig/specialSellingPrice',
            },
          ],
        },
        {
          name: '交易活动配置',
          subNodes: [
            {
              name: '销售价活动',
              path: '/tradingActivities/sellActivities',
            },
            // {
            //   name: '采购价活动',
            //   path: '/tradingActivities/purchaseActivities',
            // },
          ],
        },
      ],
    },
    {
      name: '系统设置',
      icon: 'setting',
      subNodes: [
        {
          name: '系统操作日志',
          subNodes: [
            {
              name: '日志纪录',
              path: '/systemSettings/operationLog/operationLog',
            },
          ],
        },
        {
          name: '权限管理',
          subNodes: [
            {
              name: '角色管理',
              path: '/systemSettings/powerManage/roleManage',
            },
            {
              name: '账号管理',
              path: '/systemSettings/powerManage/accountManage',
            },
          ],
        },
        {
          name: '系统配置',
          subNodes: [
            {
              name: '系统配置',
              path: '/systemSettings/systemConfig/systemConfig',
            },
          ],
        },
        {
          name: '下载中心',
          subNodes: [
            {
              name: '下载列表',
              path: '/systemSettings/downloadCenter',
            },
          ],
        },
        {
          name: '系统消息',
          subNodes: [
            {
              name: '消息管理',
              path: '/systemSettings/systemNotice/noticeManage',
            },
          ],
        },
      ],
    },
  ];
  return buildId(menuList);
}

export { buildId, getPermissionMenu };
