<!--
 * @Descripttion: PermissionLayout
 * @Author: 汪佳彪
 * @date: 2021-11-02 17:04
-->
<template>
  <div class="permission-layout">
    <div class="permission-layout-aside">
      <div
        :style="{ backgroundImage: `url(${getLogo})` }"
        :class="['custom-logo', `is-${type}`]"></div>
      <div class="menu" :style="{ height: `calc(100% - ${logoHeight}px)` }">
        <!-- trigger="hover" -->
        <ykc-aside
          :data="menuData"
          :defaultActiveMenuId="defaultActiveMenuId"
          @menu-item-click="handleMenuItemClick"></ykc-aside>
      </div>
    </div>
    <div class="permission-layout-main" :style="mainStyle">
      <ykc-header
        :username="username"
        :title="pageTitle"
        @logout="handleLogout"
        @change-password="onChangePasswd">
        <!-- <i
          slot="prepend"
          v-if="!!$route.meta.BackButtonVisible"
          class="ykc-icon-arrow-left"
          style="cursor: pointer; color: #000000; opacity: 0.25"
          @click="handleClickBack"></i> -->
        <MapDialog slot="map" :type="type" />
        <DownloadDialog slot="download" />
        <NoticeDialog v-rbac="'sys:notice:headerdialog'" slot="notice" />
      </ykc-header>
      <div
        class="permission-layout-content"
        :style="{ height: `calc(100vh - ${type === 'expand' ? 73 : 54}px)` }">
        <keep-alive :include="cacheRE">
          <router-view ref="RouterView" :key="$route.path"></router-view>
        </keep-alive>
      </div>
    </div>
    <!--修改用户密码弹窗-->
    <ChangeUserPassword
      :show-cwd.sync="showEditOwnPassword"
      @show-change-passwd="onChangePasswdDrawerChange" />
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { buildId } from './get-permission-menu';
  import { sysDict, sysCnf } from '@/service/apis';
  import windowResizeMixin from '@/components/ykc-ui/utils/window-resize-mixin';
  import { localSetItem, sessionSetItem, sessionGetItem, sessionRemoveItem } from '@/utils';

  import MapDialog from './MapDialog.vue';
  import NoticeDialog from './NoticeDialog.vue';
  import DownloadDialog from './DownloadDialog.vue';
  import ChangeUserPassword from '@/views/systemSettings/changeUserPassword/ChangeUserPassword.vue';

  function dfs(tree, result) {
    tree.forEach(item => {
      result.push(item);
      if (Array.isArray(item.subNodes) && item.subNodes.length > 0) {
        dfs(item.subNodes, result);
      }
    });
  }

  const findFirstPage = menu => {
    if (menu.path) return menu.path;
    if (menu.subNodes && menu.subNodes.length) return findFirstPage(menu.subNodes[0]);
    return '/NotFound';
  };

  export default {
    name: 'PermissionLayout',
    components: {
      MapDialog,
      NoticeDialog,
      DownloadDialog,
      ChangeUserPassword,
    },
    data() {
      const DFSMenuData = [];
      const menuData = buildId(
        sessionGetItem('menuData').filter(item => item.permissionCode !== 'overview:operation')
      );
      dfs(menuData, DFSMenuData);
      return {
        menuData,
        DFSMenuData,
        cacheRE: /^.*List$/, // 缓存组件正则，需要缓存的组件，务必配置 name 字段，且满足该正则
        logoHeight: 0,
        footerHeight: 20,
        username: sessionGetItem('accountNo'),
        pageTitle: '',
        defaultActiveMenuId: '',
        showEditOwnPassword: false,
      };
    },
    computed: {
      ...mapGetters(['getLogo']),
      mainStyle() {
        return { width: `calc(100% - ${this.type === 'expand' ? 80 : 60}px` };
      },
    },
    mixins: [windowResizeMixin],
    // eslint-disable-next-line
    beforeRouteEnter(to, from, next) {
      if (!sessionGetItem('accountNo')) {
        window.open('/login', '_self');
      } else {
        if (to.path === '/') {
          const menuData = sessionGetItem('menuData').filter(
            item => item.permissionCode !== 'overview:operation'
          );
          if (!menuData || !menuData.length) {
            return window.open('/login', '_self');
          }
          const firstPage = findFirstPage(menuData[0]);
          return next(firstPage);
        }
        next();
      }
      // getLoginUserInfo().then(res => {
      //   console.log(res);
      //   // 如果已登录，则可以跳转
      //   if (res && res.body) {
      //     next(vm => {
      //       console.log(res.body);
      //       vm.username = res.body.realName;
      //     });
      //   } else {
      //     // 未登录，则跳转登录页
      //     window.open('/login', '_self');
      //   }
      // });
    },
    watch: {
      $route: {
        handler(route) {
          this.$nextTick(this.calculateSize);
          this.$nextTick(() => {
            const {
              path,
              meta: { activeMenuPath, pageTitle },
            } = route;
            const activeMenu = this.DFSMenuData.find(
              item => !!item.path && [path, activeMenuPath].includes(item.path)
            );
            this.pageTitle = pageTitle;
            if (activeMenu) {
              this.defaultActiveMenuId = activeMenu.id;
            }
          });
        },
        immediate: true,
        deep: true,
      },
    },
    provide() {
      return {
        PermissionLayout: this,
      };
    },
    created() {
      this.simpleCnf();
    },
    mounted() {
      this.queryDictList();
      this.queryMapMenuList();
      this.$nextTick(() => {
        this.calculateSize();
      });
    },
    methods: {
      ...mapMutations(['setLogo']),
      // logo查询
      simpleCnf() {
        sysCnf
          .simpleCnf({})
          .then(res => {
            console.log(res);
            this.setLogo(res.logoPath);
          })
          .catch(() => {
            this.setLogo(null);
          });
      },
      // 功能地图
      queryMapMenuList() {
        if (sessionGetItem('mapMenuList')) {
          return;
        }
        sysDict
          .queryMapMenuList(sessionGetItem('accountId'))
          .then(res => {
            console.log(res);
            sessionSetItem('mapMenuList', JSON.stringify(res));
          })
          .catch(() => {
            sessionRemoveItem('mapMenuList');
          });
      },
      // 字典
      queryDictList() {
        if (sessionGetItem('dictionary')) {
          return;
        }

        sysDict.queryDictList({}).then(res => {
          localSetItem('dictionary', JSON.stringify(res));
          sessionSetItem('dictionary', JSON.stringify(res));
        });
      },
      clearKeepAliveCache() {
        this.cacheRE = new RegExp(`^${Date.now()}$`);
        this.$nextTick().then(() => {
          this.cacheRE = /^.*List$/;
        });
      },
      handleClickBack() {
        this.$router.back();
      },
      calculateSize() {
        const isExpanded = this.type === 'expand';
        const logoHeight = isExpanded ? 73 : 54;
        this.logoHeight = logoHeight;
      },
      handleLogout() {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '是否确认退出系统？',
          onConfirm: done => {
            sessionStorage.clear();
            window.open('/login', '_self');
            // logout().then(() => {
            done();
            //   window.open('/login', '_self');
            // });
          },
        });
      },
      handleMenuItemClick(menus) {
        // console.log(menus.map(item => item.id));
        this.clearKeepAliveCache();
        const last = menus.slice(-1)[0];
        if (last && last.path) {
          this.$router.push(last.path);
        } else {
          console.error('菜单路径未配置');
          console.log('给对应菜单项配置 path 字段并与路由对应');
        }
      },
      onChangePasswd() {
        // todo 显示密码弹窗，对接接口，修改后要做退出操作
        this.showEditOwnPassword = true;
      },
      // 监听修改密码组件传出的事件
      onChangePasswdDrawerChange(val) {
        console.log('passwd drawer change val:', val);
        this.showEditOwnPassword = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/components/ykc-ui/styles/var.scss';
  .permission-layout {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    &-aside {
      display: flex;
      flex-direction: column;
      background-color: $_23252D;
      .logo {
        color: $_FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        letter-spacing: 1px;
        background-image: linear-gradient(-45deg, #84befc 0%, #307aff 100%);
        transition: all 0.3s ease;
        i {
          transition: all 0.3s ease;
          &.is-expand {
            font-size: 21.2px;
          }
          &.is-collapse {
            font-size: 16.2px;
          }
        }
      }
      .custom-logo {
        width: 80px;
        height: 73px;
        transition: all 0.3s ease;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &.is-expand {
          width: 80px;
          height: 73px;
        }
        &.is-collapse {
          width: 60px;
          height: 54px;
        }
      }
    }
    &-main {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    &-content {
      background-color: $_F6F7F8;
      overflow: hidden;
    }
    &-content .card-page-list .el-scrollbar__wrap {
      display: flex;
      flex-flow: column;
      .el-scrollbar__view {
        display: flex;
        flex-flow: column;
        flex: 1;
        > div {
          display: flex;
          flex-flow: column;
          flex: 1;
          .ykc-card-page {
            display: flex;
            flex-flow: column;
            flex: 1;
          }
        }
      }
    }
  }
</style>
