<template>
  <div class="map" ref="map">
    <el-popover
      placement="bottom"
      trigger="manual"
      v-model="showMapDialog"
      :popper-class="`map-popover map-popover-${type}`">
      <div class="map-dialog" ref="mapDialog">
        <div class="map-dialog-left">
          <div class="map-dialog-left-bg"></div>
          <div class="search">
            <div class="search-select">
              <ykc-dropdown
                :configSet="configSet"
                :data="menuList"
                :remote="true"
                :clearable="true"
                v-model="dropdownVal"
                placeholder="搜索功能"></ykc-dropdown>
            </div>
            <div class="search-list">
              <div
                v-for="(item, index) in menuList"
                :key="index"
                :class="[
                  'search-list-li',
                  index > 7 ? 'search-list-li8' : '',
                  item.menuName === selectMenuVal ? 'search-list-active' : '',
                ]"
                @click="selectClick(item)">
                {{ item.menuName }}
              </div>
            </div>
          </div>
        </div>
        <div class="map-dialog-right">
          <el-scrollbar tab="div" view-class="map-dialog-right-scrollbar">
            <div class="map-dialog-right-li" v-for="item in selectMenuList" :key="item.id">
              <template v-if="item.subNodes && item.subNodes.length > 0">
                <div class="li-h2" v-for="item1 in item.subNodes" :key="item1.id">
                  <div
                    class="li-h2-name"
                    :class="item1.subNodes && item1.subNodes.length > 0 ? '' : 'li-h2-name-hover'"
                    @click="handleClick(item1)">
                    {{ item1.menuName }}
                  </div>
                  <template v-if="item1.subNodes && item1.subNodes.length > 0">
                    <div class="li-h3" v-for="item2 in item1.subNodes" :key="item2.id">
                      <div
                        @click="handleClick(item2)"
                        class="li-h3-name"
                        :class="
                          item2.subNodes && item2.subNodes.length > 0
                            ? 'li-h3-name-bottom'
                            : 'li-h3-name-hover'
                        ">
                        {{ item2.menuName }}
                      </div>
                      <template v-if="item2.subNodes && item2.subNodes.length > 0">
                        <div class="li-h4" v-for="item3 in item2.subNodes" :key="item3.id">
                          <div class="li-h4-name" @click="handleClick(item3)">
                            {{ item3.menuName }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div class="map-dialog-right-li-bottom"></div>
          </el-scrollbar>
        </div>
      </div>
      <div class="map-btn" slot="reference" @click="showMapDialog = !showMapDialog"></div>
    </el-popover>
  </div>
</template>
<script>
  import YkcDropdown from '@/components/ykc-ui/dropdown';
  import { sessionSetItem, sessionGetItem } from '@/utils';
  import { sysDict } from '@/service/apis';

  export default {
    components: {
      YkcDropdown,
    },
    props: {
      type: {
        type: String,
        default: 'expand',
      },
    },
    data() {
      return {
        showMapDialog: false,
        selectMenuVal: '',
        dropdownVal: '',
        menuList: [],
        selectMenuList: [],
        configSet: {
          value: 'id',
          label: 'menuName',
        },
      };
    },
    watch: {
      dropdownVal(val) {
        if (val) {
          const selectMenuValFilter = this.menuList.filter(item => item.id === val);
          this.selectMenuVal = selectMenuValFilter[0]?.menuName || '';
          if (this.selectMenuVal) {
            this.updateList(this.selectMenuVal);
          }
        }
      },
      showMapDialog(val) {
        if (val && this.menuList.length === 0) {
          this.queryMapMenuList();
        }
      },
    },
    mounted() {
      const handler = ({ target }) => {
        if (this.showMapDialog) {
          if (
            [this.$refs.mapDialog, this.$refs.map].every(
              el => el !== target && !el.contains(target)
            )
          ) {
            this.showMapDialog = false;
          }
        }
      };
      // 点击关闭下载中心弹窗
      window.document.addEventListener('click', handler);
      this.$on('hook:destroyed', () => {
        window.document.removeEventListener('click', handler);
      });

      const menuList =
        sessionGetItem('mapMenuList')?.filter(
          item => item.permissionCode !== 'overview:operation'
        ) || [];
      this.menuList = this.sortTree(menuList);
    },
    methods: {
      sortTree(tree) {
        tree.sort((a, b) => a.orderNum - b.orderNum);
        tree.map(item => this.sortTree(item.subNodes));
        return tree;
      },
      queryMapMenuList() {
        sysDict
          .queryMapMenuList(sessionGetItem('accountId'))
          .then(res => {
            console.log(res);
            sessionSetItem('mapMenuList', JSON.stringify(res));
            this.menuList = res || [];
          })
          .catch(() => {
            sessionSetItem('mapMenuList', null);
          });
      },
      // 页面跳转
      handleClick(val) {
        if (val.routeUrl) {
          this.showMapDialog = false;
          this.$router.push(val.routeUrl);
        }
      },
      // 选中一级菜单
      selectClick(val) {
        this.selectMenuVal = val.menuName;
        this.updateList(val.menuName);
      },
      // 更新选择列表
      updateList(menuName) {
        const selectMenuList1 = [];
        const selectMenuList2 = [];
        const selectMenuList3 = [];
        const selectMenuList4 = [];
        const selectMenuListData = this.menuList.filter(item => item.menuName === menuName);
        selectMenuListData[0].subNodes.forEach((item, index) => {
          if (index % 4 === 0) {
            selectMenuList1.push(item);
          }
          if (index % 4 === 1) {
            selectMenuList2.push(item);
          }
          if (index % 4 === 2) {
            selectMenuList3.push(item);
          }
          if (index % 4 === 3) {
            selectMenuList4.push(item);
          }
        });
        this.selectMenuList = [
          {
            subNodes: selectMenuList1,
          },
          {
            subNodes: selectMenuList2,
          },
          {
            subNodes: selectMenuList3,
          },
          {
            subNodes: selectMenuList4,
          },
        ];
      },
    },
  };
</script>
<style lang="scss">
  .ykc-header {
    .map {
      position: relative;
      &-btn {
        cursor: pointer;
        margin-left: 20px;
        margin-right: 20px;
        width: 22px;
        height: 22px;
        background: no-repeat url('~@/assets/images/systemSettings/header-map.png');
        background-size: 100% 100%;
      }
    }
  }
  .map-popover {
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1), inset 0px 1px 0px 0px #f0f1f5;
    border-radius: 0;
    padding: 0;
    .popper__arrow {
      left: 48px !important;
    }
    .map-dialog {
      height: 500px;
      display: flex;
      &-left {
        position: relative;
        width: 320px;
        &-bg {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 230px;
          height: 230px;
          background: no-repeat url('~@/assets/images/systemSettings/header-map-bg.png');
          background-size: 100% 100%;
        }
        .search {
          width: 100%;
          &-select {
            width: 260px;
            height: 40px;
            border-radius: 2px;
            margin: 30px 30px 20px;
          }
          .ykc-dropdown .el-input__inner {
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            background: #f6f7f8;
            border: none;
            padding-left: 10px;
          }
          &-list {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            box-sizing: border-box;
            height: 431px;
            padding-bottom: 30px;
            padding-left: 40px;
            &-li {
              cursor: pointer;
              margin: 0 20px 24px 0px;
              padding-bottom: 3px;
              width: 56px;
              box-sizing: border-box;
              border-bottom: 1px solid transparent;
            }
            &-li8 {
              margin-left: -44px;
            }
            &-active {
              color: #4d8cfd;
              border-bottom: 1px solid #1779fe;
            }
          }
        }
      }
      &-right {
        box-sizing: border-box;
        flex-grow: 1;
        background: rgba(241, 242, 245, 0.92);
        &-scrollbar {
          // flex-grow: 1;
          // min-width: 460px;
          max-height: 500px;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          padding-top: 2px;
          padding-left: 38px;
        }
        &-li {
          width: 180px;
          display: flex;
          flex-direction: column;
          &:not(:first-child) {
            margin-left: 60px;
          }
          &-bottom {
            width: 100%;
            height: 30px;
          }
          .li-h2 {
            margin-top: 28px;
            &-name {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #4d8cfd;
              line-height: 20px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              padding-bottom: 8px;
              border-bottom: 1px solid #d6dbe3;
              &-hover {
                cursor: pointer;
                &:hover {
                  color: #4d8cfd;
                }
              }
            }
            .li-h3 {
              &-name {
                margin-top: 16px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 17px;
                &-bottom {
                  margin-bottom: 16px;
                }
                &-hover {
                  cursor: pointer;
                  &:hover {
                    color: #4d8cfd;
                  }
                }
              }
              .li-h4 {
                width: 100%;
                background: rgb(228, 230, 236, 0.5);
                border-radius: 2px;
                box-sizing: border-box;
                &-name {
                  font-size: 12px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #000000;
                  line-height: 17px;
                  padding: 8px 0 8px 10px;
                  cursor: pointer;
                  &:hover {
                    color: #4d8cfd;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .map-popover-expand {
    width: calc(100vw - 80px);
    top: 50px !important;
    left: 80px !important;
  }
  .map-popover-collapse {
    width: calc(100vw - 60px);
    top: 35px !important;
    left: 60px !important;
    .map-dialog-right {
      &-scrollbar {
        padding-top: 2px;
        padding-left: 8px;
      }
      &-li {
        margin-left: 30px !important;
      }
    }
  }
</style>
