<!--
 * 错误页面
 -->
<template>
  <div class="not-found">
    <div class="box">
      <div class="icon"></div>
      <div class="text">很抱歉，页面丢失了，请重新找回……</div>
      <ykc-button class="btn" @click="handleBack">返回首页</ykc-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        time: 5,
      };
    },
    methods: {
      handleBack() {
        window.open('/', '_self');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .box {
      min-height: 500px;
      min-width: 372px;
      text-align: center;
      .icon {
        width: 401px;
        height: 372px;
        background-image: url('~@/assets/images/404.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }
      .text {
        color: #000;
        margin: 39px auto 16px;
        font-size: 14px;
      }
      .btn {
        border-radius: 21.5px;
      }
    }
  }
</style>
