<template>
  <div>
    <div class="notice" ref="noticeDialog">
      <div class="notice-btn" slot="reference" @click="showNoticeDialogChange"></div>
      <div v-if="showNoticeList" class="notice-dialog">
        <div class="notice-dialog-head">
          <div class="notice-dialog-head-left">
            <div class="notice-dialog-head-left-title">公告消息</div>
          </div>
          <div class="notice-dialog-head-right" @click="clearNoticeRecord('dialog')">
            <div class="notice-dialog-head-right-icon"></div>
            <div class="notice-dialog-head-right-text">全部已读</div>
          </div>
        </div>
        <div class="notice-dialog-list">
          <el-scrollbar tab="div" view-class="notice-dialog-list-scrollbar">
            <template v-if="noticeList.length">
              <div
                v-for="(item, index) in noticeList"
                :key="index"
                class="notice-dialog-list-li"
                :class="{ 'notice-dialog-list-li-read': item.pushStatus === 2 }"
                @click="openDetail(item)">
                <div>
                  <div class="notice-dialog-list-li-title">{{ item.noticeTitle }}</div>
                  <div class="notice-dialog-list-li-type">
                    类型：{{ noticeTypeFormat(item.noticeType) }}
                  </div>
                  <div class="notice-dialog-list-li-time">{{ item.publishTime }}</div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="notice-dialog-list-none">暂无公告消息</div>
            </template>
          </el-scrollbar>
          <div class="notice-dialog-footer">
            <div class="notice-dialog-footer-btn" @click="moreNoticeRecord">更多公告</div>
          </div>
        </div>
      </div>
    </div>

    <ykc-operation-dialog
      customClass="detail"
      :hasContentBorder="true"
      :show.sync="showDetailDialog"
      size="large"
      :show-footer="false"
      :customTitle="true">
      <template slot="title">
        <div class="detail-title">
          <div class="detail-title-left">{{ noticeTitle }}</div>
          <div class="detail-title-right">推送时间：{{ publishTime }}</div>
        </div>
      </template>
      <template slot="content">
        <div class="dialog-operation-content">
          {{ noticeContent }}
        </div>
      </template>
    </ykc-operation-dialog>
    <ykc-operation-dialog
      customClass="notice"
      :show.sync="showNoticeTable"
      size="large"
      :show-footer="false"
      :customTitle="true">
      <template slot="title">
        <div class="notice-title">
          <div class="notice-title-left">公告消息</div>
          <div class="notice-title-right">
            <div class="notice-title-right-icon"></div>
            <div class="notice-title-right-text" @click="clearNoticeRecord('table')">全部已读</div>
          </div>
        </div>
      </template>
      <template slot="content">
        <NoticeTable ref="noticeTable" @rowClick="rowClick" />
      </template>
    </ykc-operation-dialog>
  </div>
</template>
<script>
  import YkcOperationDialog from '@/components/ykc-ui/operation-dialog';
  import NoticeTable from './NoticeTable.vue';
  import { sysNotice } from '@/service/apis';
  import { sessionGetItem } from '@/utils';

  export default {
    components: {
      NoticeTable,
      YkcOperationDialog,
    },
    data() {
      return {
        showNoticeTable: false,
        publishTime: '',
        noticeTitle: '',
        noticeContent: '',
        showDetailDialog: false,
        noticeList: [],
        showNoticeList: false,
      };
    },
    watch: {
      showNoticeList(val) {
        if (val) {
          this.updateNoticeList();
        }
      },
    },
    mounted() {
      const handler = ({ target }) => {
        if (this.showNoticeList) {
          if ([this.$refs.noticeDialog].every(el => el !== target && !el.contains(target))) {
            this.showNoticeList = false;
          }
        }
      };
      // 点击关闭下载中心弹窗
      window.document.addEventListener('click', handler);
      this.$on('hook:destroyed', () => {
        window.document.removeEventListener('click', handler);
      });
    },
    methods: {
      noticeTypeFormat(val) {
        const noticeTypeMap = {
          0: '营销公告',
          1: '系统公告',
        };
        return noticeTypeMap[val];
      },
      // 消息弹窗单行点击
      openDetail(row) {
        console.log('row', row);
        if (row.pushStatus !== 2) {
          sysNotice.allRead([row.id]).then(res => {
            console.log(res);
          });
        }
        this.showNoticeList = false;
        this.showDetailDialog = true;
        this.noticeTitle = row.noticeTitle;
        this.publishTime = row.publishTime;
        this.noticeContent = row.noticeContent;
      },
      // table 单行点击
      rowClick(row) {
        console.log('row', row);
        this.showNoticeTable = false;
        this.showDetailDialog = true;
        this.noticeTitle = row.noticeTitle;
        this.publishTime = row.publishTime;
        this.noticeContent = row.noticeContent;
      },
      // 展示消息弹窗
      showNoticeDialogChange() {
        this.showNoticeList = !this.showNoticeList;
      },
      // 消息列表
      updateNoticeList() {
        sysNotice
          .queryNoticeListByOpera({ accountId: sessionGetItem('accountId'), current: 1, size: 10 })
          .then(res => {
            console.log(res);
            this.noticeList = res?.records || [];
          });
      },
      // 消息已读
      clearNoticeRecord(val) {
        if (val === 'dialog') {
          this.noticeList = this.noticeList.map(item => {
            item.pushStatus = 2;
            return item;
          });
        } else if (val === 'table') {
          this.$refs.noticeTable.setRead();
        }
        sysNotice.allRead([]).then(res => {
          console.log(res);
        });
      },
      // 更多公告
      moreNoticeRecord() {
        this.showNoticeList = false;
        this.showNoticeTable = true;
      },
    },
  };
</script>
<style lang="scss">
  .ykc-header {
    .notice {
      position: relative;
      &-btn {
        cursor: pointer;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
        height: 20px;
        width: 20px;
        background: no-repeat url('~@/assets/images/systemSettings/header-notice.png');
        background-size: 20px 20px;
        background-origin: content-box;
        position: relative;
        &::before {
          content: ' ';
          position: absolute;
          left: -20px;
          top: 2px;
          height: 16px;
          border-left: 1px solid #e2e0e0;
        }
      }
      &-dialog {
        position: absolute;
        z-index: 99999;
        top: 46px;
        right: 0;
        width: 400px;
        height: 600px;
        background: #ffffff;
        box-shadow: 3px 4px 30px 1px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        &-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 65px;
          position: relative;
          box-sizing: border-box;
          padding-left: 30px;
          padding-right: 30px;
          &::after {
            content: ' ';
            position: absolute;
            left: 30px;
            bottom: 0;
            width: 340px;
            height: 1px;
            background: #ebebf0;
          }
          &-left {
            &-title {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 25px;
            }
            &-des {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4d8cfd;
              line-height: 17px;
            }
          }
          &-right {
            cursor: pointer;
            display: flex;
            align-items: center;
            &-icon {
              width: 12px;
              height: 12px;
              background: no-repeat url('~@/assets/images/systemSettings/notice-read.png');
              background-size: 100% 100%;
            }
            &-text {
              margin-left: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 17px;
            }
          }
        }
        &-list {
          width: 100%;
          height: 473px;
          .notice-dialog-list-scrollbar {
            height: 473px;
          }
          &-none {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #000000;
          }
          &-li {
            cursor: pointer;
            margin-left: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 340px;
            height: 106px;
            box-sizing: border-box;
            &-read {
              opacity: 0.6;
            }
            &:hover {
              opacity: 1;
              margin-left: 20px;
              padding-left: 10px;
              padding-right: 10px;
              width: 360px;
              background: #f6f7f8;
              border-radius: 2px;
            }
            &-title {
              width: 340px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &-type {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4a4a4a;
              line-height: 17px;
              margin-top: 8px;
              margin-bottom: 4px;
            }
            &-time {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4a4a4a;
              line-height: 17px;
            }
          }
        }
        &-footer {
          width: 100%;
          height: 60px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: ' ';
            position: absolute;
            left: 30px;
            top: 0;
            width: 440px;
            height: 1px;
            background: #ebebf0;
          }
          &-btn {
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
          }
        }
      }
    }
  }
  .detail {
    .dialog-operation-content {
      padding: 7px 15px 7px 7px;
    }
    .detail-title {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      &-left {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-right {
        white-space: nowrap;
        margin-left: 8px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .notice {
    .notice-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      &-left {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &-right {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        top: 1px;
        &-icon {
          width: 12px;
          height: 12px;
          background: no-repeat url('~@/assets/images/systemSettings/notice-read.png');
          background-size: 100% 100%;
        }
        &-text {
          white-space: nowrap;
          margin-left: 4px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
</style>
