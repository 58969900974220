<template>
  <div class="download" ref="downloadDialog">
    <div style="display: flex">
      <div
        v-rbac="'URL:overview:operation'"
        class="download-btn"
        style="margin-right: 8px"
        type="plain"
        @click="handleOverview">
        <div class="download-btn-text" style="margin-left: 0">运营大屏</div>
      </div>
      <div class="download-btn" type="plain" @click="showDownloadDialogChange">
        <div class="download-btn-icon"></div>
        <div class="download-btn-text">下载中心</div>
      </div>
    </div>
    <div v-if="getDownloadDialog" class="download-dialog">
      <div class="download-dialog-head">
        <div class="download-dialog-head-left">
          <div class="download-dialog-head-left-title">下载中心</div>
          <div class="download-dialog-head-left-des">文件仅支持保存7天，超时需重新前往下载</div>
        </div>
        <!-- <div class="download-dialog-head-right" @click="clearRecord">
          <div class="download-dialog-head-right-icon"></div>
          <div class="download-dialog-head-right-text">清空纪录</div>
        </div> -->
      </div>
      <div class="download-dialog-list">
        <el-scrollbar tab="div" view-class="download-dialog-list-scrollbar">
          <template v-if="downloadList.length">
            <div v-for="(item, index) in downloadList" :key="index" class="download-dialog-list-li">
              <div>
                <div class="download-dialog-list-li-title">{{ item.fileName || '——' }}</div>
                <div class="download-dialog-list-li-type">
                  下载类型：{{ downloadTypeFormat(item.downloadType) }}
                </div>
                <div class="download-dialog-list-li-time">{{ item.fileGenerateTime || '——' }}</div>
              </div>
              <div class="download-dialog-list-li-right">
                <div class="download-dialog-list-li-right-btn" v-if="item.downloadStatus === '1'">
                  <ykc-button @click="doDownload(item)">下载</ykc-button>
                </div>
                <div
                  class="download-dialog-list-li-right-generate"
                  v-if="item.downloadStatus === '0'">
                  <div class="download-dialog-list-li-right-generate-icon"></div>
                  <div class="download-dialog-list-li-right-generate-text">数据生成中</div>
                </div>
                <div class="download-dialog-list-li-right-fail" v-if="item.downloadStatus === '2'">
                  <div class="download-dialog-list-li-right-fail-icon"></div>
                  <div class="download-dialog-list-li-right-fail-text">下载失败</div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="download-dialog-list-none">暂无数据</div>
          </template>
        </el-scrollbar>
      </div>
      <div class="download-dialog-footer">
        <div class="download-dialog-footer-btn" @click="moreRecord">更多记录</div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex';
  import YkcButton from '@/components/ykc-ui/button';
  import { sysDownload } from '@/service/apis';
  import { localGetItem, sessionGetItem } from '@/utils';

  export default {
    components: {
      YkcButton,
    },
    data() {
      return {
        downloadList: [],
        showDownloadDialog: true,
      };
    },
    computed: {
      ...mapGetters(['getDownloadDialog']),
    },
    watch: {
      getDownloadDialog(val) {
        if (val) {
          this.updateDownload();
        }
      },
    },

    mounted() {
      const handler = ({ target }) => {
        if (this.getDownloadDialog) {
          if ([this.$refs.downloadDialog].every(el => el !== target && !el.contains(target))) {
            this.setDownloadDialog(false);
          }
        }
      };
      // 点击关闭下载中心弹窗
      window.document.addEventListener('click', handler);
      this.$on('hook:destroyed', () => {
        window.document.removeEventListener('click', handler);
      });
    },
    methods: {
      ...mapMutations(['setDownloadDialog']),
      handleOverview() {
        console.log('handleOverview');
        this.$router.push('/overview/operation');
      },
      downloadTypeFormat(code) {
        const download_type = localGetItem('dictionary')?.download_type || [];
        const [property] = download_type.filter(item => item.code === code);
        return property ? property.name : '—' || '—';
      },
      /**
       * @description 展示下载弹窗
       */
      showDownloadDialogChange() {
        this.setDownloadDialog(!this.getDownloadDialog);
      },
      // 下载中心列表
      updateDownload() {
        sysDownload
          .queryDownFileList({
            createdBy: sessionGetItem('accountId'),
            current: 1,
            size: 10,
          })
          .then(res => {
            console.log(res);
            this.downloadList = res?.records || [];
          });
      },
      // 清空纪录
      clearRecord() {
        this.downloadList = [];
      },
      // 下载
      doDownload(item) {
        sysDownload.download(item.id).then(res => {
          window.open(res);
        });
      },
      // 更多纪录
      moreRecord() {
        this.setDownloadDialog(false);
        this.$router.push({
          path: '/systemSettings/downloadCenter',
        });
      },
    },
  };
</script>
<style lang="scss">
  .ykc-header {
    .download {
      position: relative;
      &-btn {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: border-box;
        width: 100px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #4d8cfd;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &-icon {
          width: 16px;
          height: 16px;
          background: no-repeat url('~@/assets/images/systemSettings/download-icon.png');
          background-size: 100% 100%;
        }
        &-text {
          margin-left: 4px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4d8cfd;
          line-height: 20px;
        }
        &:hover {
          border: 1px solid #386cc8;
          .download-btn-icon {
            background: no-repeat url('~@/assets/images/systemSettings/download-icon-active.png');
            background-size: 100% 100%;
          }
          .download-btn-text {
            color: #386cc8;
          }
        }
      }
      &-dialog {
        position: absolute;
        z-index: 99999;
        top: 46px;
        right: 0;
        width: 500px;
        height: 600px;
        background: #ffffff;
        box-shadow: 3px 4px 30px 1px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        &-head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 90px;
          position: relative;
          box-sizing: border-box;
          padding-left: 30px;
          padding-right: 60px;
          &::after {
            content: ' ';
            position: absolute;
            left: 30px;
            bottom: 0;
            width: 440px;
            height: 1px;
            background: #ebebf0;
          }
          &-left {
            &-title {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 25px;
              margin-bottom: 8px;
            }
            &-des {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4d8cfd;
              line-height: 17px;
            }
          }
          &-right {
            cursor: pointer;
            display: flex;
            align-items: center;
            &-icon {
              width: 12px;
              height: 12px;
              background: no-repeat url('~@/assets/images/systemSettings/download-reset.png');
              background-size: 100% 100%;
            }
            &-text {
              margin-left: 4px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 17px;
            }
          }
        }
        &-list {
          width: 100%;
          height: 450px;
          .download-dialog-list-scrollbar {
            height: 450px;
          }
          &-none {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #000000;
          }
          &-li {
            margin-left: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 440px;
            height: 106px;
            box-sizing: border-box;
            &:hover {
              margin-left: 20px;
              padding-left: 10px;
              padding-right: 10px;
              width: 460px;
              background: #f6f7f8;
              border-radius: 2px;
            }
            &-title {
              width: 330px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &-type {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4a4a4a;
              line-height: 17px;
              margin-top: 8px;
              margin-bottom: 4px;
            }
            &-time {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #4a4a4a;
              line-height: 17px;
            }
            &-right {
              flex-grow: 1;
              padding-right: 20px;
              &-btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              &-generate {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-icon {
                  width: 24px;
                  height: 24px;
                  background: no-repeat url('~@/assets/images/systemSettings/download-icon.png');
                  background-size: 100% 100%;
                }
                &-text {
                  margin-top: 6px;
                  font-size: 12px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #4d8cfd;
                  line-height: 17px;
                }
              }
              &-fail {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &-icon {
                  width: 24px;
                  height: 24px;
                  background: no-repeat url('~@/assets/images/systemSettings/download-fail.png');
                  background-size: 100% 100%;
                }
                &-text {
                  margin-top: 6px;
                  font-size: 12px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #d0021b;
                  line-height: 17px;
                }
              }
            }
          }
        }
        &-footer {
          width: 100%;
          height: 60px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: ' ';
            position: absolute;
            left: 30px;
            top: 0;
            width: 440px;
            height: 1px;
            background: #ebebf0;
          }
          &-btn {
            cursor: pointer;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
